<template lang="pug">
  v-container
    v-data-table(
      :server-items-length="rackEggIndexGetCount"
      :items="rackEggIndexList"
      :headers="headers"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      dense
      :loading="rackEggIndexGetting"
    )
      template(v-slot:top)
        v-row
          v-col(cols="12" lg="6" md="6")
            v-text-field(
              label="search"
              dense
              append-icon="mdi-magnify"
              v-model="search"
            ).mb-0.pb-0
          v-col(cols="12" lg="6" md="6")
            v-text-field(
              label="Date"
              dense
              type="date"
              v-model="date"
            ).mt-0.pt-0
      template(v-slot:item.incubator_rack_id="{ item }")
        span.font-weight-bold.primary--text {{ item.rack_code.code }}
        v-icon( 
          v-if="accessLevel > 6"
          color="yellow darken-3"
          @click="$emit('edit', item)"
        ) mdi-pencil
      template(v-slot:item.date="{ value }")
        span {{ value.dateFormat() }}
</template>
<script>
import rackEggRepository from '@/repositories/rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [rackEggIndexVars, rackEggIndexVarNames] = requestVars({ identifier: 'rack-egg-index', pagination: true })
const rackEggIndexHandler = new VueRequestHandler(null, rackEggIndexVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'Rack No.', value: 'incubator_rack_id' },
    { text: 'Qty', value: 'quantity' },
    { text: 'Date', value: 'date' },
  ],
  page: 1,
  search: null,
  date: null,
  itemsPerPage: 10,
})

export default {
  name: 'TableRackEgg',
  data () {
    return {
      ...rackEggIndexVars,
      ...tableVars(),
    }
  },
  computed: {
    accessLevel () {
      return this.$store.state.auth.accessLevel
    },
    rackEggIndexParams () {
      return {
        search: this.search,
        date: this.date,
        page: this.page,
        limit: this.itemsPerPage,
      }
    },
  },
  created () {
    this.getRackEggs()
    this.initWebsocket()
  },
  watch: {
    rackEggIndexParams () {
      this.getRackEggs()
    },
    search () {
      this.page = 1
    },
    date () {
      this.page = 1
    },
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const storeEvent = ({ model }) => {
        if (model === 'RackEgg') {
          this.getRackEggs()
        }
      }
      const updateEvent = ({ model, data }) => {
        if (model === 'RackEgg') {
          const setRackEgg = item => {
            if (item.id === data.id) return Object.assign({ ...data })
            return Object.assign({ ...item })
          }
          this.rackEggIndexList = this.rackEggIndexList.map(setRackEgg)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', storeEvent)
        .listen('DBStoreManyEvent', storeEvent)
        .listen('DBUpdateEvent', updateEvent)
    },
    getRackEggs () {
      const handler = rackEggIndexHandler
      const repository = rackEggRepository.index
      handler.setVue(this)
      handler.execute(repository, [this.rackEggIndexParams])
    },
  },
}
</script>